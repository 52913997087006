// import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorType } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { AppManifest } from '@wix/app-manifest-builder';
import { addWidgetToLightbox, installLightbox } from './editor/lightbox';

// const presets = { Desktop: 'variants-lgnn37aw', Mobile: 'variants-lxyg0q1a' };

const MODALS = [
  {
    // detailsModal...
    id: '1a4c1ccb-de8d-4dbf-ae6e-640bb4a8d842',
    persetId: 'variants-lxx1s9zw1',
    title: 'Details Modal',
    tpaPageId: 'detailsModal',
    width: 700,
  },
  {
    // errorModal...
    id: '9d75f05d-6f77-4d86-b529-a85eab8a1162',
    persetId: 'variants-ly4468ob',
    title: 'Error Modal',
    tpaPageId: 'errorModal',
    width: 500,
  },
];

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (options.firstInstall) {
    const isResponsive = options.origin.type === EditorType.Responsive;

    for (const modal of MODALS) {
      const lightboxRef = await installLightbox({
        editorSDK,
        appDefId,
        widgetId: modal.id,
        title: modal.title,
        tpaPageId: modal.tpaPageId,
        isResponsive,
        width: modal.width,
      });
      addWidgetToLightbox({
        editorSDK,
        lightboxRef,
        widgetId: modal.id,
        scopedPresets: {
          desktop: {
            layout: modal.persetId,
            style: modal.persetId,
          },
        },
        isResponsive,
        width: modal.width,
      });
    }

    const { id: pageId } = (
      await editorSDK.document.pages.getApplicationPages('token')
    )[0];

    await editorSDK.document.pages.navigateTo('token', {
      pageLink: { type: 'PageLink', pageId: pageId! },
    });
  }
};

export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);

  appManifestBuilder.configureController(
    `popupController_detailsModal`,
    (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        `popupContainer_detailsModal`,
        (componentBuilder) => {
          componentBuilder.set({ displayName: 'Details Modal' });
          componentBuilder.behavior();

          componentBuilder
            .gfpp()
            .set('mainAction2', { behavior: 'HIDE' })
            .set('layout', { behavior: 'HIDE' })
            .set('settings', { behavior: 'HIDE' });
        },
      );
    },
  );

  appManifestBuilder.configureController(
    `popupController_errorModal`,
    (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        `popupContainer_errorModal`,
        (componentBuilder) => {
          componentBuilder.set({ displayName: 'Error Modal' });
          componentBuilder.behavior();

          componentBuilder
            .gfpp()
            .set('mainAction2', { behavior: 'HIDE' })
            .set('layout', { behavior: 'HIDE' })
            .set('settings', { behavior: 'HIDE' });
        },
      );
    },
  );

  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      pagesBuilder.addAction({
        type: 'page_delete',
      });
      pagesBuilder.addSettingsTab(
        {
          type: 'page_info',
        },
        {
          type: 'permissions',
        },
        {
          type: 'layout',
        },
      );
    })
    .withJsonManifest(baseManifest)
    .build();
};
