import type {
  ComponentRef,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

const LIGHTBOX_CONNECTED_PARAMS = {
  CONTROLLER: 'popupController',
  ROLE: 'popupContainer',
};

const TOKEN = 'token';

type presetIds = {
  layout: string;
  style: string;
};

type presetBreakpoints = 'desktop' | 'tablet' | 'mobile';

const widgetLayouts = {
  containerLayout: {},
  spx: {
    refWidth: 1200,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'auto',
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
  },
  itemLayout: {
    alignSelf: 'center',
    margins: {
      left: {
        type: 'percentage',
        value: 0,
      },
      right: {
        type: 'percentage',
        value: 0,
      },
      top: {
        type: 'percentage',
        value: 0,
      },
      bottom: {
        type: 'percentage',
        value: 0,
      },
    },
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    justifySelf: 'center',
    type: 'GridItemLayout',
  },
  type: 'SingleLayoutData',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

const getLightboxLayouts = (width) => {
  return {
    containerLayout: {
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    spx: {
      refWidth: 1200,
      resolverType: 'scale',
    },
    componentLayout: {
      hidden: false,
      height: {
        type: 'percentage',
        value: 100,
      },
      type: 'ComponentLayout',
      width: {
        type: 'percentage',
        value: 100,
      },
      maxWidth: {
        type: 'px',
        value: width,
      },
    },
    itemLayout: {
      type: 'FixedItemLayout',
      margins: {
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
      },
      justifySelf: 'center',
      alignSelf: 'center',
    },
    type: 'SingleLayoutData',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
};

export const installLightbox = async ({
  editorSDK,
  appDefId,
  widgetId,
  title,
  tpaPageId,
  isResponsive,
  width = 700,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  widgetId: string;
  title: string;
  tpaPageId: string;
  isResponsive: boolean;
  width?: number;
}) => {
  const popupRef = await editorSDK.document.pages.popupPages.addConnected(
    TOKEN,
    {
      title,
      shouldNavigateToPage: isResponsive,
      controllerType: `${LIGHTBOX_CONNECTED_PARAMS.CONTROLLER}_${tpaPageId}`,
      popupRole: `${LIGHTBOX_CONNECTED_PARAMS.ROLE}_${tpaPageId}`,
      definition: {
        data: {
          // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
          // @ts-ignore
          appDefinitionId: appDefId,
          managingAppDefId: appDefId,
          hidePage: false,
          tpaPageId,
          pageBackgrounds: {
            desktop: {
              custom: true,
              isPreset: false,
              ref: {
                type: 'BackgroundMedia',
                color: '#000',
                // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
                // @ts-ignore
                colorOpacity: 0.6,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0,
              },
            },
            mobile: { custom: false, isPreset: false },
          },
        },
        components: [
          {
            type: 'Container',
            id: widgetId,
            skin: 'wysiwyg.viewer.skins.area.RectangleArea',
            layout: {
              height: 320,
              width,
              x: 0,
              y: 0,
            },
            layouts: isResponsive ? getLightboxLayouts(width) : undefined,
            componentType: 'wysiwyg.viewer.components.PopupContainer',
            props: {
              type: 'PopupContainerProperties',
            },
          },
        ],
        style: {
          type: 'ComponentStyle',
          style: {
            properties: {
              'alpha-bg': '0.6',
              bg: 'color_15',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              bg: 'theme',
            },
            groups: {},
          },
          componentClassName: 'mobile.core.components.Page',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
          pageId: '',
          compId: '',
        },
      },
    },
  );

  const [lightboxComponent] = await editorSDK.document.components.getChildren(
    TOKEN,
    {
      componentRef: popupRef,
    },
  );

  return lightboxComponent ?? popupRef;
};

export const addWidgetToLightbox = async ({
  editorSDK,
  lightboxRef,
  widgetId,
  scopedPresets,
  isResponsive,
  width = 700,
}: {
  editorSDK: FlowEditorSDK;
  lightboxRef: ComponentRef;
  widgetId: string;
  scopedPresets: Partial<Record<presetBreakpoints, presetIds>>;
  isResponsive: boolean;
  width?: number;
}) => {
  await editorSDK.application.appStudioWidgets.addWidget(TOKEN, {
    widgetId,
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      fixedPosition: false,
      height: 320,
      width,
      x: 0,
      y: 0,
    },
    layouts: isResponsive ? widgetLayouts : undefined,
    scopedPresets,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    containerRef: lightboxRef as any,
  });
};
